/* Common css*/
    .allSkillBoxes {
        display: flex;
        flex-wrap:wrap;
        justify-content: center
    }
    
    
    
    .skillU {
        font-family: 'Didot', monospace;
        font-size: small;
        margin: 0px !important;
        color: #E3CDC9;

    }

    .linkIcon {
        font-family: 'Didot', monospace;
        font-size: x-small;
        color:#354639;
        float: center;        
        margin-top: 10px;
        margin-bottom: 0px;
        margin-left: 40%;
    }

    /* For wide computer screen*/
    @media only screen and (min-width: 770px) {

        .headLines {
            margin-top: 50px;
            font-family: 'Didot', monospace;
            font-size:large;
            color:#E3CDC9;
            text-decoration: underline;
            padding-left: 10px;
        }

        .skillbox {
            margin: 20px;
            text-align: center;
            max-width: 200px;
        }
    
        .skill {
            font-family: 'Didot', monospace;
            font-size: small;
            color: #E3CDC9;
            margin-top: 10px;
            margin-bottom: 0px;
            width: 150px;
            
        }

    
    }

    /* For mobile screen*/
    @media only screen and (max-width: 770px) {

        .headLines {
            margin-top: 50px;
            font-family: 'Didot', monospace;
            font-size:medium;
            color:#E3CDC9;
            text-decoration: underline;
            padding-left: 10px;
        }

        .skillbox {
            margin: 5px;
            text-align: center;
            max-width: 100px;
        }
    
        .skill {
            font-family: 'Didot', monospace;
            font-size: small;
            color: #E3CDC9;
            margin-top: 10px;
            margin-bottom: 5px;
            width: 100px;
            
        }
    }
