
.contentDiv {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    justify-content: center;
    width: auto;
    background-color: #FFF9EB;
    margin-bottom: 100px;
    border-radius: 5px;
}



.underHeadline {
    color: #000000;
    font-family: 'Didot', monospace;
    font-size: large;
    font-size: 1.75rem;
    font-weight: 500;
    text-decoration: underline;

}

.separator{
    display: flex;
    align-items: center !important;
    
  }
  
  .separator h1{
    padding: 0 1rem; /* creates the space */
  }
  
  .separator .line1{
    flex: 1;
    height: 1px;
    background-color: #E3CDC9;
  }

  .separator .line2{
    flex: 10;
    height: 1px;
    background-color: #E3CDC9;
  }


.text {
    margin: 25px;
    font-size: 16px !important;
    font-family: 'Didot', monospace;
    font-weight: 500;
    max-width: 400px;

}



/* For wide computer screen*/
@media only screen and (min-width: 770px) {

.horizontalPhoto {
    width: 300px;
    margin-top: 30px;
    margin-bottom: 30px;

}

.verticalPhoto {
    height: 350px;
    margin-top: 30px;
    margin-bottom: 30px;
}
}

@media only screen and (max-width: 991px) {

.contentDiv:nth-child(even) {
  flex-direction: column-reverse;
}

}

/* For mobile screen*/
@media only screen and (max-width: 770px) {

    .horizontalPhoto {
        width: 200px;
        margin-top: 30px;
        margin-bottom: 30px;
    
    }
    
    .verticalPhoto {
        height: 250px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    
    }

