
.background {
    position: "fixed";
    height: 100%;
    top: 0;
    left: 0;
}


.container {
    width: 100%;
    height: 100%;
    margin: auto;
}

.context {
    height: 100% !important;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    align-content: center;

}


.content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    align-self: center;
    
}



/* For wide computer screen*/
@media only screen and (min-width: 770px) {

 .innerContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    align-self: center;
    margin-top: 25%;
}

.billede {
    height: 300px;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    border-radius: 50%;
    margin-bottom: 20px;

}

h1 {
    display: flex;
    color:#FFF9EB;
    text-align: center;
    font-family: 'Didot', monospace;

}

h2 {
    display: flex;
    color:#FFF9EB;
    text-align: center;
    font-family: 'Didot', monospace;

}
}

/* For mobile screen*/
@media only screen and (max-width: 770px) {

    .innerContent {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        align-content: center;
        align-self: center;
        margin-top: 40%;
    }

    .billede {
        height: 200px;
        display: flex;
        align-items: center;
        align-content: center;
        align-self: center;
        border-radius: 50%;
        margin-bottom: 20px;
    
    }
    
    h1 {
        display: flex;
        color:#FFF9EB;
        text-align: center;
        font-family: 'Didot', monospace;
        font-size:x-large;
    
    }
    
    h2 {
        display: flex;
        color:#FFF9EB;
        text-align: center;
        font-family: 'Didot', monospace;
        font-size: large;
    }
 }

