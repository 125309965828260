@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@200&display=swap');



.navbar-expand-sm {
  height: 100px;
  margin: 0px;

}


.d-sm-inline-flex {
position: absolute;
right: 10px;
top: 0px;
}

.mb-3 {
  margin: 0px;
}
.nav-container.container{
  margin: 0px !important;
}

.nav-text {
  color: #FFF9EB!important;
  font-family: 'Didot', monospace;
  margin-left: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  
}


/* For wide computer screen*/
@media only screen and (min-width: 576px) {

  a.navbar-brand {
    white-space: normal;
    color:#FFF9EB !important;
    font-family: 'Didot', monospace;
    font-size: 20px;
    max-width: 20px !important;

  }

  .navbar-nav {
    float: right;
    text-align: left;

  }
}
/* For mobile screen*/
@media only screen and (max-width: 576px) {

  a.navbar-brand {
    white-space: normal;
    color:#FFF9EB !important;
    font-family: 'Didot', monospace;
    font-size: 15px;
  }

  .d-sm-inline-flex {
    position: relative;
  }
  .navbar-nav{
    float: right;
    text-align: left;
    background-color:#959778;
    width: 95%;
    z-index: 1;
    position: relative
  }

  .nav-text {
    color:  #FFF9EB !important;

  }

  .navbar-toggler-icon {
    background-image: url("./Images/burgermenu.png") !important;
  }
  
  .mr-2.navbar-toggler {
    border: none;
    float: right;

  }

  }
  



