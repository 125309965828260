
.social-container {
    padding: 25px 50px;
  }

  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    transform: translateY(-2px);
  }

  .mailSocial:hover {
    transform: translateY(-2px);
  }

  .resume:hover {
    transform: translateY(-2px);

  }


  

  /* For wide computer screen*/
@media only screen and (min-width: 770px) {

  a.linkedin {
    color: #354639 !important;
  }
  

  .resume {
    color: hsl(134, 14%, 24%);
    margin: 0 1rem;
    margin-bottom: 30px;
    transition: transform 250ms;
    display: inline-block;
    

  }
  

  .mailSocial {
    color: #354639;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

}

 /* For mobile screen*/
 @media only screen and (max-width: 770px) {

  a.linkedin {
    color: #354639 !important;
    font-size: 13px;
  }
  
  .resume {
    color: #354639;
    height: 38px;
    margin: 0 1rem;
    width: auto;
    margin-bottom: 20px;

  }
  
  .mailSocial {
    color: #354639;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
    font-size: 38px;

  }

}
  