
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

 

  /* For wide computer screen*/
@media only screen and (min-width: 770px) {

  .social-container {
    padding: 25px 50px;
  }

  .linkedinSocial {
    color: #eeeae1;
    margin: 0 1rem;
    font-size: 50px;
  }
  
  .resumeBottom {
    color: #eeeae1;
    margin: 0 1rem;
    margin-bottom: 30px;
    transition: transform 250ms;
    display: inline-block;

  }

  .mailSocialBottom {
    color: #eeeae1;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
    font-size: 50px;
  }
}

  /* For mobile screen*/
  @media only screen and (max-width: 770px) {

    .social-container {
      padding: 0px 50px 50px 50px;
    }
    .linkedinSocial {
      color: #eeeae1;
      margin: 0 1rem;
      font-size: 40px;
    }
    
    
    .resumeBottom {
      color: #eeeae1;
      margin: 0 1rem;
      margin-bottom: 20px;
      height: 40px;
      width: auto;
      transition: transform 250ms;
      display: inline-block;
  
    }
  
    .mailSocialBottom {
      color: #eeeae1;
      margin: 0 1rem;
      transition: transform 250ms;
      display: inline-block;
      font-size: 40px;

    }
  }

  
  
  